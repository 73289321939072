<template>
  <section>
    <PollFilter />
    <Preloader v-if="callingAPI" />
    <PollTable />
  </section>
</template>

<script>
import { mapState } from 'vuex'
import Preloader from '@/components/preloader/Preloader'
import PollFilter from '@/components/poll/PollFilterVlm'
import PollTable from '@/components/table/PollTableVlm'

export default {
  name: 'PollListViewVlm',
  computed: {
    ...mapState([
      'callingAPI'
    ])
  },
  components: {
    PollTable,
    Preloader,
    PollFilter
  }
}
</script>
